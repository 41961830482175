//////// CONTEXT
//////// ESTABLISHES GLOBAL SET OF VALUES THAT COMPONENTS USE WITH PASSING EACH INDIVIDUALLY DOWN THE LINE. ////////

export const SET_LOADING = 'SET_LOADING'

export const ADD_READER_SUCCESS = 'ADD_READER_SUCCESS'
export const ADD_READER_ERROR = 'ADD_READER_ERROR'
export const LOGIN_READER_SUCCESS = 'LOGIN_READER_SUCCESS'
export const LOGIN_READER_ERROR = 'LOGIN_READER_ERROR'
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS'
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR'
export const LOGOUT_READER = 'LOGOUT_READER'
export const FETCH_SUBMISSIONS_SUCCESS = 'FETCH_SUBMISSIONS_SUCCESS'
export const FETCH_SUBMISSIONS_ERROR = 'FETCH_SUBMISSIONS_ERROR'
export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS'
export const CREATE_SUBMISSION_ERROR = 'CREATE_SUBMISSION_ERROR'
export const DELETE_SUBMISSION_ERROR = 'DELETE_SUBMISSION_ERROR'
export const FETCH_SINGLE_SUBMISSION_SUCCESS = 'FETCH_SINGLE_SUBMISSION_SUCCESS'
export const FETCH_SINGLE_SUBMISSION_ERROR = 'FETCH_SINGLE_SUBMISSION_ERROR'
export const VERARBEITEN_SUBMISSION_SUCCESS = 'VERARBEITEN_SUBMISSION_SUCCESS'
export const VERARBEITEN_SUBMISSION_ERROR = 'VERARBEITEN_SUBMISSION_ERROR'
export const UPDATE_READER_SUCCESS = 'UPDATE_READER_SUCCESS'
export const UPDATE_READER_ERROR = 'UPDATE_READER_ERROR'
